import { FC } from "react"
import { RecruiterDealFormProps } from "../../../../interfaces"
import BasicInformation from "./BasicInformation"
import CommissionDeal from "./commission-deals"


const RecruiterDealForm: FC<RecruiterDealFormProps> = ({ recruiterId, recruiterDeal, setNonRecoverable, nonRecoverable,
  underTraining, setUnderTraining, getValues, control, errors, register, setError, setValue, tiers, tiersForDeletion, setTiersForDeletion }) => {
  return <>
    <BasicInformation recruiterId={recruiterId} getValues={getValues} recruiterDeal={recruiterDeal} setNonRecoverable={setNonRecoverable} setUnderTraining={setUnderTraining}
      underTraining={underTraining} control={control} errors={errors} nonRecoverable={nonRecoverable}
      register={register} setValue={setValue} />
    <CommissionDeal setError={setError} getValues={getValues} tiersForDeletion={tiersForDeletion} setTiersForDeletion={setTiersForDeletion} tiers={tiers || []} errors={errors} control={control}
      register={register} setValue={setValue} />
  </>
}
export default RecruiterDealForm