import { useEffect, useState } from "react"
import { useFieldArray } from "react-hook-form"
import { Col, Row } from "reactstrap"
import CustomButton from "../../../../layout/CustomButton"
import Form from "./FormFields"

const CommissionTiers = ({ tierType, register, control, setError, tiers, errors, setValue, getValues, tiersForDeletion, setTiersForDeletion }) => {
  const [disableAdd, setDisableAdd] = useState<boolean>(false);
  const { fields, append, remove } = useFieldArray({
    name: tierType,
    control
  })

  useEffect(() => {
    tiers?.forEach(({ id, ...rest }) => append({
      id, ...rest
    }))
    if (tiers[tiers?.length - 1]?.endRange?.includes("+")) {
      setDisableAdd(true)
    }
  }, [])

  return <Row>
    <Col sm='12' className="mb-4 mt-4 custom-form-input form-input">
      <div className="font-weight-bold">{tierType === 'tempTiers' ? 'Contract ' : 'Direct Hire'} Commission Tier</div>
    </Col>
    {fields?.map(({ id, commissionPercentage, ...rest }, index) => (
      <Form
        setTiersForDeletion={setTiersForDeletion} tiersForDeletion={tiersForDeletion}
        startRange={rest?.startRange?.value || rest?.startRange} commissionPercentage={commissionPercentage}
        endRange={rest?.endRange?.value || rest?.endRange} id={tiers[index]?.id} setDisableAdd={setDisableAdd}
        setValue={setValue} register={register} serial={index} errors={errors} tiersType={tierType}
        key={id} remove={remove} control={control} getValues={getValues} isLast={index === fields?.length - 1}
        baseRate={tierType === 'tempTiers' ? getValues("contractingBaseRate") : getValues("dhBaseRate")} />))}

    <Col sm='12' className="mb-3 custom-form-input form-input">
      <CustomButton
        buttonText="+ Add Tier"
        className="btn-primary w-100"
        disabled={disableAdd}
        buttonClick={() => {
          console.log(`getValues("contractingBaseRate") ;; `, getValues("contractingBaseRate"))
          if (tierType === 'tempTiers' && !getValues("contractingBaseRate"))
            setError("contractingBaseRate", { type: "manual", message: "Please Select Base Rate first" })
          else if (!getValues("dhBaseRate"))
            setError("dhBaseRate", { type: "manual", message: "Please Select Base Rate first" })
          else
            append({})
        }}
      />
    </Col>
  </Row>
}
export default CommissionTiers