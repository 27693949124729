import { FC, useContext, useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { Col, Label, Row } from "reactstrap"
import { AppContext } from "../../../../../context"
import { CommissionTierProps } from "../../../../../interfaces"
import { BILLING_CREDIT_END_RANGE, BILLING_CREDIT_START_RANGE, COMMISSION_PERCENTAGE_LIST } from "../../../../../utils/constant"
import { customSelectStyleNew } from "../../../../styled/customSelect"

const Form: FC<CommissionTierProps> = ({ tiersType, serial, setValue, id, register, errors, isLast,
  remove, commissionPercentage, baseRate, setDisableAdd, endRange, getValues, startRange, control, tiersForDeletion, setTiersForDeletion }) => {
  const { theme } = useContext(AppContext)
  const [endRangeList, setEndRangeList] = useState<{ label: string, value: string }[]>([])
  const [commissionPercentageList, setCommissionPercentageList] = useState<{ label: string, value: string }[]>([])
  const [startRangeList, setStartRangeList] = useState<{ label: string, value: string }[]>([])

  useEffect(() => {
    setStartRangeList(BILLING_CREDIT_START_RANGE)
    setEndRangeList(BILLING_CREDIT_END_RANGE)
  }, [])


  useEffect(() => {
    setCommissionPercentageList(COMMISSION_PERCENTAGE_LIST(baseRate!))
  }, [baseRate])

  const removeAddedTier = (serial: number) => {
    if (isLast && getValues(`${tiersType}.${serial}.endRange`)?.value === '+') {
      setDisableAdd(false)
    }
    remove(serial)
  }
  return <Col className="mb-4" sm='12'>
    <Row>
      <Col sm="12" className="d-flex align-items-center justify-content-between my-3"><Label className="text-14 text-blackele p-0 m-0">Tier {serial + 1}</Label>
        <button className="remove-tier-btn" onClick={() => {
          if (getValues(`${tiersType}.${serial}.tierId`)) {
            setTiersForDeletion([...tiersForDeletion, getValues(`${tiersType}.${serial}.tierId`)])
          }
          removeAddedTier(serial)
        }}>- Remove Tier </button>
      </Col>
      <Controller
        name={`${tiersType}.${serial}.tierId`}
        defaultValue={id}
        control={control}
        render={(field) => <input hidden />}
      />
      <Col sm='6' className="mb-3 ">
        <Controller
          name={`${tiersType}.${serial}.startRange`}
          control={control}
          defaultValue={startRange && { value: startRange, label: startRange }}
          rules={{ required: { value: true, message: "Billing Credit Starting Range is a required field" } }}
          render={(field) => (
            <Select
              {...field}
              options={startRangeList}
              onFocus={() => {
                const oldEndingRange = getValues(`${tiersType}.${serial - 1}.endRange`)?.value
                if (oldEndingRange) {
                  const indexToFilter = BILLING_CREDIT_END_RANGE.findIndex(item => item.value === oldEndingRange);
                  setStartRangeList(BILLING_CREDIT_START_RANGE.filter((_, index) => index > indexToFilter));
                }
              }}
              onChange={(value) => {
                const indexToFilter = BILLING_CREDIT_START_RANGE.findIndex(item => item.value === value.value);
                setEndRangeList(BILLING_CREDIT_END_RANGE.filter((_, index) => index >= indexToFilter));
                const currentSelectedEndIndex = BILLING_CREDIT_END_RANGE.findIndex(item => item.value === getValues(`${tiersType}.${serial}.endRange`)?.value)
                if (indexToFilter > currentSelectedEndIndex) {
                  setValue(`${tiersType}.${serial}.endRange`, '')
                }
                field.onChange(value);
                setValue(`${tiersType}.${serial}.startRange`, value); // Custom logic for setting the value
              }}
              styles={{ ...customSelectStyleNew(theme) }}
              placeholder="Billing Credit Starting Range"
            />
          )}
        />

        <small className="text-danger pt-2">
          {!!errors?.[`${tiersType}`]?.length && errors?.[`${tiersType}`][`${serial}`]?.startRange?.message}
        </small>
      </Col>

      <Col sm='6' className="mb-3">
        <Controller
          name={`${tiersType}.${serial}.endRange`}
          defaultValue={endRange && { value: endRange, label: endRange }}
          rules={{ required: { value: true, message: "Billing Credit End Range is a required field" } }}
          control={control}
          render={(field) => (
            <Select
              {...field}
              options={endRangeList}
              onFocus={() => {
                const startRange = getValues(`${tiersType}.${serial}.startRange`)?.value
                if (startRange) {
                  const indexToFilter = BILLING_CREDIT_START_RANGE.findIndex(item => item.value === startRange);
                  setEndRangeList(BILLING_CREDIT_END_RANGE.filter((_, index) => index >= indexToFilter));
                }
              }}
              onChange={(value) => {
                const indexToFilter = BILLING_CREDIT_END_RANGE.findIndex(item => item.value === value.value);
                const nextSelectedStartIndex = BILLING_CREDIT_START_RANGE.findIndex(item => item.value === getValues(`${tiersType}.${serial + 1}.startRange`)?.value)
                if (indexToFilter >= nextSelectedStartIndex) {
                  setValue(`${tiersType}.${serial + 1}.startRange`, '')
                }
                if (isLast) {
                  setDisableAdd(value.value === '+' ? true : false)
                }
                setValue(`${tiersType}.${serial}.endRange`, value);
                field.onChange(value);
              }}
              styles={{ ...customSelectStyleNew(theme) }}
              placeholder="Billing Credit End Range"
            />
          )}
        />

        <small className="text-danger pt-2">
          {!!errors?.[`${tiersType}`]?.length && errors?.[`${tiersType}`][`${serial}`]?.endRange?.message}
        </small>
      </Col>
    </Row>

    <Col sm='12' className="mb-3 p-0">
      <Controller
        name={`${tiersType}.${serial}.commissionPercentage`}
        defaultValue={commissionPercentage && baseRate && { label: `Base Rate (${baseRate}) + ${commissionPercentage}`, value: commissionPercentage }}
        rules={{ required: { value: true, message: "Billing Credit End Range is a required field" } }}
        control={control}
        render={(field) => (
          <Select
            {...field}
            options={commissionPercentageList}
            styles={{ ...customSelectStyleNew(theme) }}
            placeholder="Bonus Rate to Add"
          />
        )}
      />

      <small className="text-danger pt-2">
        {!!errors?.[`${tiersType}`]?.length && errors?.[`${tiersType}`][`${serial}`]?.commissionPercentage?.message}
      </small>
    </Col>

  </Col>
}
export default Form