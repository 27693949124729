import { useMutation } from "@apollo/client"
import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { Col } from "reactstrap"
import { SAVE } from "../../../../constants"
import { RecruiterDealDrawerProps } from "../../../../interfaces"
import { CREATE, RECRUITER_DEAL_DRAWER_HEADING, UPDATE } from "../../../../utils/constant"
import { formatDealInput } from "../../../../utils/helper"
import { DrawerHeader, Sidedrawer } from "../../../layout/Sidedrawer"
import { CREATE_DEAL, UPDATE_DEAL } from "../gql"
import RecruiterDealForm from "./Form"

const RecruiterDealDrawer: FC<RecruiterDealDrawerProps> = ({ refetch, isOpen, isEdit, recruiterDeal, recruiterId, toggle }) => {
  const [nonRecoverable, setNonRecoverable] = useState<boolean>(false)
  const [underTraining, setUnderTraining] = useState<boolean>(false)
  const [tiersForDeletion, setTiersForDeletion] = useState<string[]>([])
  const { control, register, setError, setValue, handleSubmit, getValues, errors } = useForm()

  // mutations
  const [createDealMutation, { loading }] = useMutation(CREATE_DEAL, {
    onCompleted: (val) => {
      toast.success("Recruiter's Deal Created Successfully")
      toggle()
      refetch()
    },
    onError: () => toast.error("Unable To Create Deal")
  })

  const [updateDealMutation, { loading: updating }] = useMutation(UPDATE_DEAL, {
    onCompleted: (val) => {
      toast.success("Recruiter's Deal Updated Successfully")
      toggle()
      refetch()
    },
    onError: () => toast.error("Unable To Update Deal")
  })

  // useEffect for initiating Tiers
  useEffect(() => {
    setNonRecoverable(Boolean(recruiterDeal?.nonRecoverableDraw))
    setUnderTraining(Boolean(recruiterDeal?.recruiterInTraining))
  }, [recruiterDeal])

  const submitForm = async (values) => {
    console.log("values :: ", values)
    const input = formatDealInput(recruiterId, nonRecoverable, underTraining, values)
    console.log("input :: ", tiersForDeletion)
    if (isEdit) {
      await updateDealMutation({
        variables: {
          input: { dealId: recruiterDeal?.id, tiersForDeletion, ...input }
        }
      })
    } else {
      await createDealMutation({
        variables: {
          input
        }
      })
    }
  }
  return <Sidedrawer isOpen={isOpen} toggle={() => { }}>
    <DrawerHeader
      buttonText={SAVE} disabled={loading || updating} loading={loading || updating} prevClick={toggle} heading={(isEdit ? UPDATE : CREATE) + RECRUITER_DEAL_DRAWER_HEADING}
      nextClick={handleSubmit(submitForm)} />
    <div className="fs-14">
      <Col className="p-0 my-4 ">
        <div className="font-weight-bold">Basic Information</div>
      </Col>
      <RecruiterDealForm setError={setError} recruiterId={recruiterId} setTiersForDeletion={setTiersForDeletion} getValues={getValues} tiers={recruiterDeal?.recruiterDealTiers! || []} recruiterDeal={recruiterDeal} underTraining={underTraining}
        nonRecoverable={nonRecoverable} setNonRecoverable={setNonRecoverable} setUnderTraining={setUnderTraining}
        control={control} errors={errors} register={register} setValue={setValue} tiersForDeletion={tiersForDeletion} />
    </div>
  </Sidedrawer>
}
export default RecruiterDealDrawer