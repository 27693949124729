import { FC } from "react"
import { CommissionDealProps } from "../../../../../interfaces"
import CommissionTiers from "./CommissionTiers"
import { TierType } from "../../../../../constants"

const CommissionDeal: FC<CommissionDealProps> = ({ register, tiersForDeletion, setTiersForDeletion, control, setError, getValues, tiers, errors, setValue }) => {

  return <>
    <CommissionTiers setError={setError} getValues={getValues} tiersForDeletion={tiersForDeletion} setTiersForDeletion={setTiersForDeletion} tierType="directHireTiers" control={control} errors={errors} register={register} setValue={setValue}
      tiers={tiers?.filter(({ tierType }) => tierType === TierType.DIRECT_HIRE)} />
    <CommissionTiers setError={setError} getValues={getValues} tiersForDeletion={tiersForDeletion} setTiersForDeletion={setTiersForDeletion} tierType="tempTiers" control={control} errors={errors} register={register} setValue={setValue}
      tiers={tiers?.filter(({ tierType }) => tierType === TierType.CONTRACT)} />
  </>
}
export default CommissionDeal